<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      Documentación
      <a href="" target="_blank" rel="noopener">documentación</a>.
    </p>
    <h3>Enlaces</h3>
    <ul>
      <li><a href="http://190.104.11.187/unicen_siiaa/cochabamba/ingreso.php" target="_blank" rel="noopener"> SIIA Cbba.</a></li>
      <li><a href="http://190.104.11.190/sia/" target="_blank" rel="noopener">SIA2</a></li>
    </ul>
    <h3>Página web</h3>
    <ul>
      <li><a href="https://unicen.edu.bo" target="_blank" rel="noopener">Sitio Principal</a></li>
      <li><a href="https://unicen.edu.bo" target="_blank" rel="noopener">Biblioteca</a></li>
      <li><a href="https://unicen.edu.bo" target="_blank" rel="noopener">COL</a></li>
      <li><a href="https://unicen.edu.bo" target="_blank" rel="noopener">Moodle Tutoriales</a></li>
      <li><a href="" target="_blank" rel="noopener">Novedades</a></li>
    </ul>
    
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
