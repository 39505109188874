<template>
  <h1> {{titulo}}</h1>

  <ol>
    <li v-for="nomb in nombres" :key="nomb">{{nomb}}</li>
</ol>
</template>


<script>
export  default {
    data(){
        return{
            titulo:"ACTIVIDADES",
            numero:14,
            nombres:["Juan","Jose","Adolfo","Pedro"]
        }
    }
}

</script>

<style>

</style>